import React from "react"
import styled from "styled-components"

import Layout from "@/components/Layout"
import { Logo } from "@/components/Image"
import EventCard from "@/components/EventCard"
import SEO from "@/components/SEO"

import useTranslation from "@/hooks/useTranslation"
import { Link } from "@/i18n"

const IndexPage = () => {
  const t = useTranslation()
  return (
    <Layout>
      <PageContainer>
        <SEO
          title={`${t("seo.title")}`}
          description={`${t("seo.description")}`}
        />
        <div className="home_logo">
          <Logo />
        </div>
        <EventCard />
      </PageContainer>
    </Layout>
  )
}

export default IndexPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .home_logo{
    padding: 20px 0;
    width: 100%;
    max-width: 300px;
    min-height: 240px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media (min-width: 768px) {
      max-width: 400px;
    }
    & > div{
      width: 100%;
      height: 100%;
    }
  }
`

