import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Image from "@/components/Image"
import { Link } from "@/i18n"
import useFormatLocale from "@/hooks/useFormatLocale"

const EventCard = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allNpcYaml {
        nodes {
          name {
            en
            ja
            ko
            zh_hans
            zh_hant
          }
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slug
        }
      }
    }
  `)

  const data = queryData.allNpcYaml.nodes

  const lng = useFormatLocale()

  return (
    <EventCardContainer>
      <div className="event_card">
        {data.map((icon) => (
          <Link
            to={icon.slug ? icon.slug : "/"}
            key={icon.avatar.childImageSharp.fluid.src}
          >
            <div className="npc_card">
              <Image
                className="npc_card-icon"
                src={icon.avatar.childImageSharp.fluid}
              />
              <span className="npc_card-des">{icon.name[lng]}</span>
            </div>
          </Link>
        ))}
      </div>
    </EventCardContainer>
  )
}

export default EventCard

const EventCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .event_card {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @media (min-width: 768px) {
      width: 50vw;
    }
    a {
      color: var(--acnh-colors-text-primary);
    }
    .npc_card {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .npc_card-icon {
      width: 80px;
      @media (min-width: 768px) {
        width: 90px;
      }
    }
    .npc_card-des {
      text-align: center;
    }
  }
`
